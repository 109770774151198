@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
}

body {
  overflow-x: hidden;
  background-color: #18202c;
}

#scroll> ::-webkit-scrollbar {
  width: .2em;
}

#scroll> ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

#scroll> ::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.overlay {
  position: absolute;
  z-index: 3;
}

.leaflet-control-attribution,
.leaflet-routing-container {
  display: none;
}

@keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.tel-class {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
  outline: none !important;
}

:root {
  --map-tiles-filter: grayscale(100%) invert(90%) contrast(100%);

  /* Bottom Sheet */
  --rsbs-backdrop-bg: rgba(20, 18, 18, 0.329);
  --rsbs-bg: #18202c;
  --rsbs-handle-bg: hsla(0, 15%, 94%, 0.541);
  --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);
  --rsbs-overlay-rounded: 16px;

}

.map-tiles-theme--lite {
  filter: none;
}

.map-tiles-theme--dark {
  filter: var(--map-tiles-filter, none);
}